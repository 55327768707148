import React from "react"
import ReactHtmlParser from "react-html-parser"
import Fade from "react-reveal/Fade"
import data from "../../state/state"
import Styles from "./header.module.css"

import { SocialIcon } from "react-social-icons"

const Header = () => {
  return (
      <div className={Styles.HeaderWrapper}>
        <Fade bottom cascade>
          <div className={Styles.HeadingWrapper}>
            <h1>
              {data.headerTagline}
            </h1>
          </div>
        </Fade>
        <Fade bottom>
          <p>{ReactHtmlParser(data.headerParagraph)}</p>
        </Fade>
        <Fade bottom>
          <div className={Styles.SocialIcon}>
            {data.social.map(socialLink => (  
              <SocialIcon 
                key={socialLink.url}
                url={socialLink.url} 
                style={{ height: 35, width: 35, marginRight: '10px' }}
                target="_blank"
              />
            ))}
          </div>
        </Fade>
      </div>
  );
}

export default Header
